import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/joaquim.jpg" // Tell webpack this JS file uses this image

import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image
import foto6 from "../images/fotoscursos/f1.jpg" // Tell webpack this JS file uses this image
import foto7 from "../images/fotoscursos/f2.jpg" // Tell webpack this JS file uses this image
import foto8 from "../images/fotoscursos/f3.jpg" // Tell webpack this JS file uses this image
import foto9 from "../images/fotoscursos/f4.jpg" // Tell webpack this JS file uses this image
import foto10 from "../images/fotoscursos/f5.jpg" // Tell webpack this JS file uses this image

import Footer from "../components/footer"
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
            <div className="texto inter-bold">
             <span className="opacity"> Curso de auditores<br></br> Welfare Quality:</span> <br></br>
             Matadouro de pequenos ruminantes
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal11} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Joaquim  Pallisera Llovedas 
                      </div>
                    
                    <div className="titulo inter-light">
                     Professor e consultor IRTA
                      </div>
                      </div>
                    
                  </div>
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                       24, 25 e 26 de Janeiro, 2023
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                      Espanhol
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                      550 € +IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/brochura-matadouro-peq-ruminantes.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                {/* <div className="form">
                <a href="https://forms.gle/ydDPexTADtbSaxne6" target="_blank" >

                
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div> */}
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-matadouro-bovinos">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Masterclass
          </div>
          <div className="text inter-regular">
          A rede Welfare Quality Network está a traçar diretrizes e protocolos que garantem o bem-estar animal em pequenos ruminantes com aceitação a nível Europeu pelas entidades retalhistas e compradores. Nesta ótica, se a cadeia produtiva pretende ultrapassar auditorias e em última instância obter selos que certificam o processo terá que implementar alterações que visam o respeito dos protocolos. Esta é uma formação para técnicos que queiram compreender a avaliação de bem-estar, auditorias e implementar protocolos provenientes da Welfare Quality Network (Consultores, entidades certificadoras e auditores internos).

            </div>
      </div>
      <div className="wrapper-logos">
        <div className="col1">
        <img className="imagem" src={logo1} alt="" />

        </div>
        <div className="col2">
        <img className="imagem" src={logo2} alt="" />

        </div>
      </div>

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
      
      </div>
      <div className="wrapper-program">

      <Accordion defaultActiveKey="0">
   <Accordion.Item eventKey="0">
      <Accordion.Header>
         + Dia 1 - 24/01/2023
      </Accordion.Header>
      <Accordion.Body>
      <div class="modulo">
                                <p><span className="date">09:00 - 10:30</span> | <span class="mod">Módulo 1</span> - <span class="txt">Introducción al Bienestar Animal, protocolos Welfare Quality y Certificación Welfair </span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">10:30 - 10:45</span> | <span className="mod">Coffee-Break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">10:45 - 12:45</span> | <span className="mod">Módulo 2</span>- <span class="txt">Indicadores de bienestar animal en matadero de ovino y caprino</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">12:45 - 14:00</span> | <span className="mod">Comida</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:00 - 15:30</span> | <span className="mod">Módulo 3</span> - <span class="txt">Indicadores de bienestar animal en matadero de ovino y caprino</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">15:30 - 15:45</span> | <span className="mod">Coffee-Break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">15:45 - 17:00</span> | <span className="mod">Módulo 4</span> - <span class="txt">Indicadores de bienestar animal en matadero y combinación de medidas</span></p>
                                </div>
      </Accordion.Body>
   </Accordion.Item>
   <Accordion.Item eventKey="1">
      <Accordion.Header>
         +   Dia 2 - 25/01/2023
      </Accordion.Header>
      <Accordion.Body>
      <div class="modulo">
                                <p><span className="date">08:00 - 13:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Práctica en matadero de ovino y caprino </span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:30</span> | <span class="txt">Comida</span></p>
                                </div>
          
                                <div class="modulo">
                                  <p><span className="date">14:30 - 17:30</span> | <span className="mod">Módulo 2 </span> - <span class="txt">Conclusiones y dudas de la práctica. Repetibilidades</span></p>
                                </div>
      </Accordion.Body>
   </Accordion.Item>
   <Accordion.Item eventKey="2">
      <Accordion.Header>
         +  Dia 3 - 26/01/2023
      </Accordion.Header>
      <Accordion.Body>
      <div class="modulo">
                                <p><span className="date">08:30 - 10:45</span> | <span class="mod">Módulo 1</span> - <span class="txt">Repetibilidades </span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">10:45 - 11:00</span> | <span class="txt">Coffee-Break</span></p>
                                </div>
          
                                <div class="modulo">
                                  <p><span className="date">11:00 - 12:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Conclusiones y dudas</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">12:00 - 14:00</span> | <span className="mod">Módulo 3</span> - <span class="txt">Exámen</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:00 - 14:15</span> | <span className="mod">Módulo 4</span> - <span class="txt">Entrega certificados y cierre del curso</span></p>
                                </div>
      </Accordion.Body>
   </Accordion.Item>

</Accordion>




      {/* <Accordion defaultActiveKey="0">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                Dia 1 - 29/03/2021
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="0">
                                <Card.Body> 
                                <div class="modulo">
                                <p><span className="date">09:00 - 10:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Apresentação de protocolos do bem estar animal</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">10:00 - 13:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Medidas comportamentais</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:00</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:00 - 17:00</span> | <span className="mod">Módulo 3</span> - <span class="txt">Medidas sanitárias e ordem das medidas na exploração</span></p>
                                </div>
  
                                </Card.Body>

                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                            Dia 2 - 30/03/2021
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="1">
                            <Card.Body> 
                                <div class="modulo">
                                <p><span className="date">08:30 - 13:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Visita à fazenda de iscas</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:00</span> | <span class="txt">Almoço</span></p>
                                </div>
          
                                <div class="modulo">
                                  <p><span className="date">14:00 - 17:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Medidas sanitárias e ordem das medidas na exploração</span></p>
                                </div>
  
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2">
                            Dia 3 - 31/03/2021
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="2">
                            <Card.Body> 
                                <div class="modulo">
                                <p><span className="date">09:00 - 13:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Repetibilidade no protocolo WQ</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:00</span> | <span class="txt">Almoço</span></p>
                                </div>
          
                                <div class="modulo">
                                  <p><span className="date">14:00 - 17:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Repetibilidade WQ. Conclusões e dúvidas</span></p>
                                </div>
  
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="3">
                            Dia 4 - 01/04/2021
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="3">
                            <Card.Body> 
                                <div class="modulo">
                                <p><span className="date">08:30 - 13:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Exame teórico e prático</span></p>
                                </div> 
                
          
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Entrega de certificados e encerramento do curso</span></p>
                                </div>
  
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion> */}
   
        </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/brochura-matadouro-peq-ruminantes.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                {/* <div className="form">
                <a href="https://forms.gle/ydDPexTADtbSaxne6" target="_blank" >

                
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
          </div> */}
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
   
        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal11} alt="" />

              </div>
              <div className="nome">
              Joaquim  Pallisera Llovedas 
              </div>
              <div className="titulo">
              Consultor de bem-estar de bovinos IRTA
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span> Veterinário especialista em bem-estar e qualidade </p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> Técnico de consultoria e investigação em bem-estar - IRTA</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> Welfair species leader -  Bovinos de carne, ovinos e suinos</p>
                  
                 
                  </div>
              </div>
            </div>
        </div>
        </div>
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
